<template>
  <div class="pt-36 bg-gray-100 pb-20">
    <div class="doc relative mx-auto rounded-lg" style="max-width: 800px">
      <section
        class="z-50 container mx-auto px-4 pdf-item py-12"
        id="termos-de-uso"
      >
        <div class="mx-auto prose text-justify">
          <h1
            class="text-left uppercase mb-4 text-gray-900 font-bold"
            style="font-size: 22px"
          >
            Termos de Uso
          </h1>

          <p class="pdf-item">
            O uso desta plataforma digital por {{ nm_rz_social }} ("{{
              nm_fantasia
            }}") está condicionado à aceitação e cumprimento aos Termos e
            Condições de Uso (“Termos de Uso”) descritos abaixo, os quais estão
            adequados às exigências contidas na Lei n. 10.406/2002 (“Código
            Civil Brasileiro”), na Lei n. 13.709/2018 (“LGPD”), na Lei n.
            12.965/2014 (“Marco Civil da Internet”) e em demais legislações
            pertinentes.
          </p>

          <p class="pdf-item">
            Estes Termos de Uso, junto de nossas Políticas de Privacidade,
            Constituem o acordo legal realizado entre você e
            {{ nm_rz_social }} (“{{ nm_fantasia }}”),inscrita no CNPJ sob o n.º
            {{ formatCNPJ(cd_cnpj) }}, endereço: {{ nm_endereco_logradouro }},
            n° {{ cd_endereco_numero }}, {{ nm_endereco_complemento }},
            {{ nm_endereco_bairro }}, {{ nm_endereco_cidade }}, CEP
            {{ formatCEP(cd_endereco_cep) }}., para utilização e acesso ao site
            da {{ nm_rz_social }} ({{ nm_site }}), bem como a qualquer
            aplicação, produto ou serviço relacionado a esta.
          </p>

          <p class="pdf-item">
            Nossa empresa poderá lhe fornecer os seguintes serviços:
            {{ ds_servicos }}. Ao acessar e utilizar nossos serviços, você
            declara que leu, entendeu e concorda com estes Termos de Uso,
            Políticas de Privacidade e demais regras expostas em nosso site.
          </p>

          <p class="pdf-item">
            Na hipótese de você não concordar com as regras dispostas nestes
            Termos de Uso, solicitamos, gentilmente, que deixe de utilizar nosso
            site e serviços imediatamente.
          </p>

          <p class="pdf-item">
            Eventualmente, podemos realizar alterações ou modificações neste
            documento. A versão atualizada dos Termos de Uso sempre será
            indicada com a data da última revisão para sua melhor visualização
            no início do documento. Sendo você o responsável por revisar estes
            Termos de Uso de tempos em tempos, a fim de verificar se ainda está
            de acordo com todas as nossas regras. Para que, na hipótese de você
            não concordar com as alterações ou modificações, você poderá, a
            qualquer momento, solicitar o cancelamento de sua conta ou deixar de
            utilizar nosso site e serviços, conforme previsto.
          </p>

          <template v-if="is_permitido_criar_conta">
            <h3>REGISTRO DE USUÁRIO</h3>

            <p class="pdf-item">
              Para a utilização da plataforma, o Usuário – pessoa física ou
              jurídica que esteja em pleno e total gozo da capacidade civil, bem
              como esteja apto à prática de todo e qualquer ato necessário à
              validade das solicitações de serviços requeridas, nos termos da
              legislação aplicável – deverá realizar seu cadastro na plataforma.
            </p>

            <p class="pdf-item">
              Ao se cadastrar, o Usuário se compromete a fornecer informações
              verídicas, completas e atualizadas, sob pena das consequências
              jurídicas e legais da apresentação de informações falsas
            </p>

            <p class="underline pdf-item">
              O login e a senha criados pelo Usuário são pessoais e
              intransferíveis, sendo o Usuário seu único e exclusivo responsável
              por mantê-las em segurança e sigilo, evitando, pois, o uso não
              autorizado de seu Cadastro por terceiros.
            </p>
          </template>

          <template v-if="is_vendido_produtos || is_servico_ecommerce">
            <h3>PRODUTOS</h3>

            <p class="pdf-item">
              Fazemos todos os esforços para exibir com a maior precisão
              possível as cores, características, especificações e detalhes dos
              produtos disponíveis no Site. No entanto, não garantimos que as
              cores, recursos, especificações e detalhes dos produtos sejam
              precisos, completos, confiáveis, atuais ou livres de outros erros.
              Além disso, seu display eletrônico pode não refletir com precisão
              as cores e detalhes reais dos produtos.
            </p>

            <p class="pdf-item">
              Os produtos estão sujeitos a disponibilidade, e não podemos
              garantir que os itens estarão em estoque.
            </p>

            <p class="pdf-item">
              Por fim, nós nos reservamos o direito de descontinuar qualquer
              produto a qualquer momento por qualquer motivo bem como alterar
              preços.
            </p>
          </template>

          <template v-if="is_servico_app || is_servico_sass_sub">
            <h3>SERVIÇOS DE ASSINATURA</h3>

            <p class="pdf-item">
              Fazemos todos os esforços para exibir com a maior precisão
              possível Todas as características, especificações e detalhes dos
              nossos produtos disponíveis no Site e/ou aplicativo. No entanto,
              não garantimos que os recursos estejam atuais ou livres de outros
              erros.
            </p>

            <p class="pdf-item">
              Os produtos estão sujeitos a disponibilidade, e não podemos
              garantir que as modalidades de pagamento sempre estarão
              disponíveis.
            </p>

            <p class="pdf-item">
              Por fim, nós nos reservamos o direito de descontinuar qualquer
              produto e/ou serviço a qualquer momento por qualquer motivo bem
              como alterar preços.
            </p>
          </template>

          <template v-if="is_servico_website">
            <h3>Regras de utilização do site</h3>

            <p class="underline pdf-item">
              Ao acessar nosso site, você está obrigado a utilizá-lo respeitando
              e observando estes Termos e Condições de Uso, bem como a
              legislação vigente, os costumes e a ordem pública.
            </p>

            <p class="underline pdf-item">
              Desta forma, você concorda que não poderá:
            </p>

            <ol class="list-none list-inside list-lower-alpha">
              <li>
                lesar direitos de terceiros, independentemente de sua natureza,
                em qualquer momento, inclusive no decorrer do uso do site;
              </li>
              <li>
                executar atos que limitem ou impeçam o acesso e a utilização do
                site, em condições adequadas, aos demais usuários;
              </li>
              <li>
                acessar ilicitamente o site ou sistemas informáticos de
                terceiros relacionados ao site ou à
                {{ nm_fantasia }} sob qualquer meio ou forma;
              </li>
              <li>
                difundir programas ou vírus informáticos suscetíveis de causar
                danos de qualquer natureza, inclusive em equipamentos e sistemas
                da {{ nm_fantasia }} ou de terceiros;
              </li>
              <li>
                realizar quaisquer atos que de alguma forma possam implicar
                qualquer prejuízo ou dano à {{ nm_fantasia }}
                ou a outros usuários;
              </li>
            </ol>

            <p class="underline pdf-item">
              Por fim, nós nos reservamos o direito de descontinuar qualquer
              produto e/ou serviço a qualquer momento por qualquer motivo bem
              como alterar preços.
            </p>
          </template>

          <template v-if="is_aceito_pagamento">
            <h3>COMPRAS E PAGAMENTOS</h3>

            <p class="pdf-item">Aceitamos as seguintes formas de pagamento:</p>

            <ul>
              <li v-if="is_aceito_cartao_debito">Cartão de Débito;</li>
              <li v-if="is_aceito_cartao_credito">Cartão de Crédito;</li>
              <li v-if="is_aceito_boleto">Boleto;</li>
              <li v-if="is_aceito_picpay">Picpay;</li>
              <li v-if="is_aceito_paypal">Paypal</li>
              <li v-if="is_aceito_outros">{{ ds_outros_pagamentos }}</li>
            </ul>

            <p>Você concorda em:</p>

            <ol class="list-none list-inside list-lower-alpha">
              <li class="pdf-item">
                Fornecer informações de conta e compras atuais completas e
                precisas para todas as compras feitas através desta plataforma
                digital;
              </li>
              <li class="pdf-item">
                Atualizar imediatamente as informações de conta e pagamento,
                para que possamos concluir suas transações e entrar em contato
                com você conforme o necessário;
              </li>
              <li class="pdf-item">
                Nos autorizar a cobrar de seu provedor de pagamentos escolhido
                por tais valores ao fazer seu pedido. Se o seu pedido estiver
                sujeito a cobranças recorrentes, então você consente que
                cobremos seu método de pagamento em uma base recorrente, sem
                exigir sua aprovação prévia para cada cobrança recorrente, até o
                momento em que você cancelar o pedido aplicável.
              </li>
            </ol>

            <p class="pdf-item">
              Nós nos reservamos o direito de corrigir quaisquer erros ou
              equívocos na precificação, mesmo que já tenhamos solicitado ou
              recebido o pagamento;
            </p>
          </template>

          <template v-if="is_permitido_vincular_conta">
            <h3 class="text-left">MÍDIA SOCIAL</h3>

            <p class="pdf-item">
              Como parte da funcionalidade desta plataforma, você pode se
              registrar através de sua conta de mídia social (“Conta de
              Terceiro”): (1) fornecendo suas informações de login através de
              nossa plataforma; ou (2) permitindo-nos acessar sua conta ,
              conforme autorizado pelos termos e condições aplicáveis que regem
              o uso da mídia social utilizada.
            </p>

            <p class="pdf-item">
              Você declara e garante que tem o direito de divulgar as
              informações de login da “conta de terceiro” utilizada para nós
              e/ou nos conceder acesso à ela, sem violação de qualquer um dos
              termos e condições que regem o uso dessa mídia social e sem nos
              obrigar a pagar quaisquer taxas ou nos sujeitar a quaisquer
              limitações de uso impostas pelo provedor de serviços terceirizado.
            </p>

            <p class="pdf-item">
              Ao nos conceder acesso a quaisquer contas de terceiros, você
              entende que (1) podemos acessar, disponibilizar e armazenar (se
              aplicável) qualquer conteúdo que você forneceu e armazenado em sua
              conta de terceiros para que esteja disponível através desta
              plataforma digital por meio de sua conta, incluindo, sem
              limitação, quaisquer listas de amigos e (2) podemos enviar e
              receber de sua conta de terceiros informações adicionais, na
              medida em que você for notificado quando vinculá-la à nossa
              plataforma.
            </p>

            <p>
              As contas de terceiros utilizadas estão sujeitas às configurações
              de privacidade que você definiu em tais serviços.
            </p>
          </template>

          <h3 class="text-left">DAS OBRIGAÇÕES E DEVERES DOS USUÁRIOS</h3>

          <p class="pdf-item">
            O usuário declara possuir plena ciência de que, ao utilizar os
            serviços de {{ nm_fantasia }}, deverá se comportar em estrita
            observância a estes Termos de Uso e à Política de Privacidade de
            nossa empresa, respeitando a legislação vigente, a moral e os bons
            costumes. O usuário concorda, ainda, em não utilizar os serviços
            oferecidos em nossa plataforma para fins ilícitos, não lesar os
            direitos e interesses de {{ nm_fantasia }}, de outros usuários e/ou
            terceiros, ou de qualquer forma prejudicar, desativar ou
            sobrecarregar os serviços, impedindo a sua normal utilização por
            {{ nm_fantasia }}, outros usuários e/ou terceiros, devendo preservar
            as disposições destes Termos de Uso.
          </p>

          <template v-if="is_atividades_proibidas">
            <p class="pdf-item">
              O usuário concorda em abster-se da prática de quaisquer dos atos
              abaixo descritos:
            </p>

            <ol class="list-none list-inside list-lower-alpha">
              <li v-if="is_proibido_alterar_violar">
                Alterar ou violar, de qualquer forma, os sistemas de
                autenticação, verificação de identidade e/ou de segurança dos
                serviços, redes ou cadastros de usuários e/ou administradores
                responsáveis pelos serviços, incluindo, mas não se limitando a
                tentativas de acessos a dados não destinados ao usuário,
                tentativa de acesso aos serviços ou contas sem autorização
                expressa para fazê-lo, ou tentativa de acesso ou alteração, de
                qualquer maneira e em qualquer nível de segurança, à rede de
                {{ nm_fantasia }};
              </li>
              <li v-if="is_proibido_promover_cortes_comunicacao">
                Promover, de forma proposital, interrupções, mudanças ou cortes
                nas comunicações do site e/ou nos serviços disponibilizados, bem
                como, efetuar ataques cibernéticos ou similares;
              </li>
              <li v-if="is_proibido_monitorar_transimitir_virus">
                Efetuar qualquer tipo de monitoramento que envolva a
                interceptação de informações que não se destinem ao usuário,
                remeter ou transmitir arquivos com vírus ou outras
                características destrutivas, que possam afetar de forma
                prejudicial a operação de um computador e/ou o bom funcionamento
                do site;
              </li>
              <li v-if="is_proibido_induzir_error">
                Utilizar qualquer programa de computador ou qualquer outra forma
                que induza a erro, com o objetivo de obter vantagens
                patrimoniais ou comerciais em favor do usuário ou de terceiros,
                não autorizadas por
                {{ nm_fantasia }} ou em desarmonia com estes Termos de Uso;
              </li>
              <li v-if="is_proibido_impedir_acesso_site">
                Efetuar ações que limitem, deneguem ou vetem qualquer usuário de
                acessar o site e utilizar os serviços.
              </li>
              <li v-if="is_proibido_outros">
                {{ ds_proibido_outros }}
              </li>
            </ol>
          </template>

          <p class="pdf-item">
            O usuário será responsável, na esfera cível e criminal, pelos danos
            patrimoniais e extrapatrimoniais, ou por qualquer outro prejuízo que
            venha a causar à {{ nm_fantasia }}, terceiros e outros usuários, em
            razão de descumprimento ou não de qualquer das disposições
            preconizadas nestes Termos de Uso, ou em quaisquer outros avisos,
            regulamentos de uso, instruções, políticas e/ou regulamentos
            editados e devidamente publicados por {{ nm_fantasia }} em seu site.
          </p>

          <h3 class="text-left">
            DOS LIMITES DA RESPONSABILIDADE PELOS SERVIÇOS PRESTADOS PELA
            <span class="uppercase"> {{ nm_fantasia }} </span>
          </h3>

          <template
            v-if="is_servico_sass_sub || is_servico_ecommerce || is_servico_app"
          >
            <p class="pdf-item">
              A <span class="capitalize">{{ nm_fantasia }}</span> possui
              contrato com acesso à internet e servidores de terceiros para o
              desenvolvimento dos serviços que se dedica, motivo pelo qual o
              usuário reconhece que os mesmos poderão, eventualmente, estar
              indisponíveis em decorrência de problemas técnicos, falhas na
              internet ou provedor, bem como por qualquer outra razão alheia a
              atos de <span class="capitalize">{{ nm_fantasia }}</span
              >, incluindo, eventos de caso fortuito ou de força maior. Desse
              modo, a <span class="capitalize">{{ nm_fantasia }}</span> não
              garante a disponibilidade, de forma contínua e ininterrupta, do
              funcionamento dos serviços prestados.
            </p>

            <p class="pdf-item">
              <span class="capitalize">{{ nm_fantasia }}</span> fica isenta de
              qualquer responsabilidade por danos e prejuízos de qualquer
              natureza que sejam decorrentes, de forma direta ou indireta, da
              interrupção ou suspensão, falha, cessação, falta de
              disponibilidade ou da descontinuação do funcionamento dos serviços
              prestados.
            </p>

            <p class="pdf-item">
              O usuário será o único responsável pelas informações remetidas
              à/ao
              <span class="capitalize">{{ nm_fantasia }}</span> e pelo
              cumprimento das obrigações dela decorrentes.
            </p>
          </template>

          <p class="pdf-item">
            Sem prejuízo dos demais direitos previstos em lei,
            <span class="capitalize">{{ nm_fantasia }}</span> tem resguardado o
            direito de regresso em face do usuário, em razão de quaisquer danos
            materiais e/ou morais que eventualmente vierem a ser demandados
            contra <span class="capitalize">{{ nm_fantasia }}</span
            >, em juízo ou fora dele, ou, ainda, que
            <span class="capitalize">{{ nm_fantasia }}</span> venha a sofrer, em
            decorrência do descumprimento de obrigações do usuário, resultante
            dos serviços disponibilizados na plataforma digital.
          </p>

          <h3 class="text-left">
            DA PROPRIEDADE INTELECTUAL E DOS DIREITOS RESERVADOS
          </h3>

          <p class="pdf-item">
            Ressaltamos que todos os direitos de propriedade intelectual
            atinentes ao nosso site pertencem a
            <span class="capitalize">{{ nm_fantasia }}</span
            >, estejam tais direitos registrados ou não.
          </p>

          <p class="pdf-item">
            Assim, o Usuário concorda em se abster de pleitear ou reclamar, a
            qualquer tempo, tais direitos, como se fossem de sua titularidade.
          </p>

          <p class="pdf-item">
            <span class="capitalize">{{ nm_fantasia }}</span> é titular de
            direitos sobre todos os bancos de dados, conteúdos, imagens,
            softwares utilizados no funcionamento do site, amparados de acordo
            com a legislação aplicável à propriedade intelectual e proteção de
            dados.
          </p>

          <p class="pdf-item">
            Os usuários estão cientes e concordam que
            <span class="capitalize">{{ nm_fantasia }}</span> é a única e
            exclusiva titular de todos os direitos autorais e de propriedade
            intelectual atinentes à plataforma digital e sobre os produtos e
            serviços prestados, abrangendo, neste sentido, nome de domínio,
            programação, banco de dados, conteúdo, arquivo, funcionalidades,
            design e outras características que se fazem necessárias –
            respectivos direitos nos são amparados por lei.
          </p>

          <p class="pdf-item">
            A utilização do nome comercial
            <span class="capitalize">{{ nm_fantasia }}</span
            >, bem como nossos produtos e serviços associados ao mesmo, de forma
            não autorizada sofrerá as consequências legais, haja vista a
            exclusiva titularidade que recai sobre nós.
          </p>

          <p class="pdf-item">
            Os presentes Termos de Uso não acarretam a cessão ou transferência
            ao usuário de quaisquer direitos relativos ao site, ou, então,
            qualquer parte de seu conteúdo. Entretanto, os usuários estão aptos
            a navegar no site apenas nos estritos termos permitidos nos
            presentes Termos de Uso.
          </p>

          <p class="pdf-item">
            É expressamente vedado ao Usuário alterar qualquer funcionalidade do
            site, ou ainda, acessar qualquer parte com o fito de criar produto
            ou serviço concorrente, inclusive que contenha características,
            funções e ideias semelhantes das dispostas em nosso site. É
            proibida, também, a utilização de dispositivo, software ou outro
            instrumento que permita a interferência nas atividades e operações
            do site ou que tenha como objetivo o acesso indevido as informações
            ou banco de dados deste.
          </p>

          <p class="pdf-item">
            Reforçamos que qualquer conduta que viole as leis de propriedade
            intelectual bem como as normas aplicáveis e as expressas vedações
            inseridas nestes Termos de Uso sujeitará o agente às consequências
            legais, incluindo indenização por eventual dano causado.
          </p>

          <p class="pdf-item">
            Nosso site, eventualmente, poderá expor determinados links que
            direcionarão para outros sites da rede mundial de computadores, o
            que não implica que referidos sites sejam de nossa propriedade.
            Assim,
            {{ nm_fantasia }} não se responsabiliza pelos conteúdos, práticas e
            serviços ofertados nesses outros sites, tendo em vista que não
            possuímos controle sobre o conteúdo dos mesmos.
          </p>

          <template v-if="is_permitido_avaliacoes">
            <h3 class="text-left">SUBMISSÕES DE CONTEÚDO</h3>

            <p class="pdf-item">
              Você reconhece e concorda que quaisquer perguntas, comentários,
              sugestões, ideias, feedback ou outras informações sobre o site ou
              nossos serviços fornecidas por você a nós não são confidenciais e
              se tornarão nossa propriedade exclusiva. Possuiremos direitos
              exclusivos, incluindo todos os direitos de propriedade
              intelectual, e teremos o direito de uso irrestrito e disseminação
              destas submissões de conteúdo para qualquer propósito legal,
              comercial ou outro, sem reconhecimento ou compensação para você.
              Você, por meio deste, renuncia a todos os direitos morais a
              qualquer um desses envios e garante que esses envios são originais
              ou que você tem o direito de enviá-los. Você concorda que não
              haverá recurso contra nós por qualquer violação alegada ou
              apropriação indevida de qualquer direito de propriedade em seus
              envios.
            </p>
          </template>

          <h3 class="text-left">DA VIGÊNCIA</h3>

          <p class="pdf-item">
            Os presentes Termos de Uso permanecerão vigentes desde o início do
            acesso, utilização ou cadastro do usuário em nosso site, a sua
            respectiva utilização, perdurando enquanto tivermos relação
            comercial com você.
          </p>

          <template v-if="is_nova_clausula">
            <h3 class="text-left">{{ nm_nova_clausula }}</h3>

            <p class="pdf-item">
              {{ ds_nova_clausula }}
            </p>
          </template>

          <h3 class="text-left">POLÍTICA DE PRIVACIDADE</h3>

          <p class="pdf-item">
            Nós reconhecemos, nos termos da Política de Privacidade e da
            legislação aplicável, a confidencialidade e segurança das
            informações prestadas pelos Usuários, empenhando-nos em resguardar o
            seu sigilo, salvo por força de lei ou ordem judicial.
          </p>

          <p v-if="ds_link_pp" class="pdf-item">
            Desta forma, também contamos com uma robusta política de
            privacidade, que pode ser acessada pelo link:
            <a href="ds_link_pp">politica de privacidade</a>
          </p>

          <template v-if="is_usuario_notificado_att">
            <h3 class="text-left">MUDANÇAS NOS TERMOS DE USO</h3>

            <p class="pdf-item">
              O Usuário será notificado por e-mail sobre eventuais alterações
              nestes Termos de Uso. A utilização da plataforma digital seguindo
              estas alterações implica na aceitação do Termo de Uso revisado.
            </p>
          </template>

          <h3 class="text-left">CONSIDERAÇÕES FINAIS</h3>

          <p class="pdf-item">
            A nulidade total ou parcial de qualquer item destes Termos de Uso
            não afastará o cumprimento da obrigação contida nos demais itens
            aqui presentes. Qualquer omissão ou tolerância de
            {{ nm_fantasia }} em relação às disposições desses Termos e
            Condições não importará em renúncia, novação ou modificação das
            obrigações do Usuário.
          </p>

          <p class="pdf-item">
            Caso tenha qualquer dúvida em relação ao presente documento, o
            USUÁRIO poderá entrar em contato através do
            <a :href="`mailto:${ds_email_contato}`">{{ ds_email_contato }}.</a>
          </p>

          <p class="pdf-item">
            Os presentes Termos de Uso, bem como a utilização da plataforma
            digital e dos serviços de {{ nm_fantasia }} serão regidos pelas leis
            em vigência na República Federativa do Brasil, sendo estipulado,
            desde já, o foro
            <template v-if="li_foro_cidades.length == 1">
              da cidade de {{ li_foro_cidades[0] }}
            </template>

            <template v-if="li_foro_cidades.length > 1">
              das cidades:
              <ul>
                <li v-for="cidade in li_foro_cidades" :key="cidade">
                  {{ cidade }}
                </li>
              </ul>
            </template>
            como o competente para dirimir quaisquer controvérsias ou
            divergências decorrentes destes Termos de Uso, utilização do site e
            serviços.
          </p>

          <p class="pdf-item">
            Estes Termos de Uso foram atualizados em
            {{ formatDate(dt_entrada_vigor) }}.
          </p>

          <template v-if="settings.settingsExtra.showPowered">
            <powered-by />
          </template>
        </div>
      </section>

      <v-style>
        .doc .container { background:
        {{ settings.settingsExtra.backgroundColor }} }
      </v-style>
      <v-style>
        .prose p, .prose li { color:
        {{ settings.settingsBody.color }}!important; font-size:
        {{ settings.settingsBody.focused_size }}!important; font-family:
        {{ settings.settingsBody.focused_font }}!important }
      </v-style>
      <v-style>
        .prose h1 { color:
        {{ settings.settingsHeading.color }}!important; } .prose h2, .prose h3,
        .prose h3 span { color: {{ settings.settingsHeading.color }}!important;
        font-size: {{ settings.settingsHeading.focused_size }}!important;
        font-family: {{ settings.settingsHeading.focused_font }}!important }
      </v-style>
      <v-style>
        .prose a { color: {{ settings.settingsLink.color }}!important;
        font-size: {{ settings.settingsLink.focused_size }}!important;
        font-family: {{ settings.settingsLink.focused_font }}!important; }
      </v-style>
    </div>
  </div>
</template>
<script>
import { functions } from '@/utils/functions';

export default {
  name: 'TermosDeUso',
  computed: {},
  mixins: [functions],
  data() {
    return {};
  },
  props: {
    settings: {
      type: Object,
      default() {
        return {
          settingsExtra: {},
          settingsHeading: {},
          settingsLink: {},
          settingsBody: {},
        };
      },
      required: false,
    },
    nm_rz_social: {
      type: String,
      default: '',
      required: false,
    },
    nm_fantasia: {
      type: String,
      default: '',
      required: false,
    },
    nm_site: {
      type: String,
      default: 'Nome Site',
      required: false,
    },
    ds_servicos: {
      type: String,
      default: 'descrićão Servicos',
      required: false,
    },
    cd_cnpj: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_logradouro: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_numero: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_complemento: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_bairro: {
      type: String,
      default: '',
      required: false,
    },
    nm_endereco_cidade: {
      type: String,
      default: '',
      required: false,
    },
    cd_endereco_cep: {
      type: String,
      default: '',
      required: false,
    },
    is_permitido_criar_conta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_ecommerce: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_sass_sub: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_website: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_servico_app: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_vendido_produtos: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_pagamento: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_cartao_debito: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_cartao_credito: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_boleto: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_picpay: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_paypal: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_aceito_outros: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_outros_pagamentos: {
      type: String,
      default: '',
      required: false,
    },
    is_permitido_vincular_conta: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_permitido_avaliacoes: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_atividades_proibidas: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_alterar_violar: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_promover_cortes_comunicacao: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_monitorar_transimitir_virus: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_induzir_error: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_impedir_acesso_site: {
      type: Boolean,
      default: false,
      required: false,
    },
    is_proibido_outros: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_proibido_outros: {
      type: String,
      default: '',
      required: false,
    },
    li_foro_cidades: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    is_nova_clausula: {
      type: Boolean,
      default: false,
      required: false,
    },
    nm_nova_clausula: {
      type: String,
      default: '',
      required: false,
    },
    ds_nova_clausula: {
      type: String,
      default: '',
      required: false,
    },
    ds_link_pp: {
      type: String,
      default: '',
      required: false,
    },
    is_usuario_notificado_att: {
      type: Boolean,
      default: false,
      required: false,
    },
    ds_email_contato: {
      type: String,
      default: '',
      required: false,
    },
    dt_entrada_vigor: {
      type: String,
      default: '',
      required: false,
    },
  },
  methods: {},
  created() {},
  metaInfo() {
    let title;
    let favicon;

    let content;
    const url = document.URL;
    if (url.includes('compliance')) {
      this.home = 'complianceOnline';
      title = 'Compliance Online';
      favicon = './img/icons-co.ico/favicon-16x16.png';

      content = 'Termos de uso e privacidade para adequação na LGPD';
    } else if (url.includes('easy')) {
      this.home = 'easyTermos';
      title =
        'Gerador de Política de Privacidade 🕵️‍ Gere Políticas de Privacidade GRATUITAS';
      favicon = './img/icons-easy.ico/favicon-16x16.png';

      content =
        'Gere documentos de Termos de Uso e Política de Privacidade para o seu site ou aplicativo sem custos. ';
    }
    process.env.VUE_APP_SYSTEM !== null && url.includes('local')
      ? (this.home = process.env.VUE_APP_SYSTEM)
      : '';

    return {
      link: [
        {
          rel: 'icon',
          href: favicon,
          type: 'image/png',
        },
      ],
      title: title,

      meta: [
        {
          name: 'description',
          content: content,
          icon: favicon,
        },
      ],
    };
  },
};
</script>

<style>
.list-lower-alpha {
  counter-reset: list;
  list-style: lower-alpha;
}

.list-lower-alpha > li::before {
  content: '' !important;
}

.vue-swatches__trigger {
  height: 36px !important;
  margin-bottom: 0 !important;
  padding: 0 !important;
  width: 150px !important;
  position: relative !important;
  border-radius: 3px !important;
  margin-top: 0px !important;
  border: 0.5px solid #ddd;
}
</style>
